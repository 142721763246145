export const links = {
  canny: {
    changelog: "https://cleanlist-ai.canny.io/changelog",
  },
  docs: "https://docs.cleanlist.ai",
  dashboard: {
    config: "/app/configuration/profile",
  },
};

export const PROSPECT_STALENESS_THRESHOLD_DAYS = 90 * 24 * 60 * 60 * 1000; // 90 days in milliseconds
export const COMPANY_STALENESS_THRESHOLD_DAYS = 180 * 24 * 60 * 60 * 1000; // 180 days in milliseconds
