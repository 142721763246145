"use client";
import { fetchCannyChangelog } from "@/app/api/canny/client";
import {
  fetchCurrentUserPlan,
  getSubscriptionStatusLabel,
} from "@/app/api/stripe/current-plan/client";
import { CannyChangelog } from "@/app/types";
import { SignedIn } from "@clerk/nextjs";
import { Button } from "@nextui-org/react";
import { useQuery } from "@tanstack/react-query";
import { CircleFadingArrowUp, Menu, X } from "lucide-react";
import Image from "next/image";

import Link from "next/link";
import { usePathname } from "next/navigation";
import { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "../../context/theme-context";
import { Loader } from "../loader.component";
import Notification from "../notification.component";
import { Profile } from "./profile/profile.component";

export const Navbar = () => {
  const { theme } = useContext(ThemeContext);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState<string>(usePathname());
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const {
    data: changelog,
    isLoading,
    error,
  } = useQuery<CannyChangelog, Error>({
    queryKey: ["cannyChangelog"],
    queryFn: fetchCannyChangelog,
  });

  const { data: subscription } = useQuery({
    queryKey: ["subscription"],
    queryFn: fetchCurrentUserPlan,
  });

  const notificationDropdownRef = useRef<HTMLDivElement>(null);
  const mobileMenuRef = useRef<HTMLDivElement>(null);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleNotificationDropdown = () => {
    setIsNotificationOpen(!isNotificationOpen);
  };

  const handleClickOutside = (event: any) => {
    if (
      notificationDropdownRef.current &&
      !notificationDropdownRef.current.contains(event.target as Node)
    ) {
      setIsNotificationOpen(false);
    }
    if (
      mobileMenuRef.current &&
      !mobileMenuRef.current.contains(event.target as Node)
    ) {
      setIsMobileMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLinkClick = () => {
    setIsNotificationOpen(false);
    setIsMobileMenuOpen(false);
  };

  const links = [
    { href: "/app", label: "Dashboard" },
    { href: "/app/lists", label: "Lists" },
    { href: "/app/prospects", label: "Prospects" },
    { href: "/app/integrations", label: "Integrations" },
    { href: "/app/organization", label: "Organization" },
  ];

  const isLinkActive = (currentPath: string, href: string) => {
    return currentPath === href;
  };

  const getPlanLabel = () => {
    if (!subscription) return "Change Plan";

    const status = getSubscriptionStatusLabel(subscription);
    const planName = subscription.plan?.name;

    if (subscription.status === "inactive") return "Change Plan";

    return `${planName} ${status !== "Active" ? `- ${status}` : ""}`;
  };

  const getPlanButtonStyle = () => {
    if (!subscription || subscription.status === "inactive") {
      return "bg-[#3E8AFF] hover:bg-[#3E8AFF]/90 text-white";
    }
    return "bg-transparent border border-[#3E8AFF] text-[#3E8AFF] dark:text-white hover:bg-[#3E8AFF]/10";
  };

  return (
    <div className="mx-auto w-full max-w-[1280px] bg-white dark:bg-[#03071C]">
      <div className="relative flex h-[68px] items-center justify-between rounded-xl border border-[#0000001A] px-3 dark:border-[#FFFFFF33] xl:pl-[43px]">
        <Link href="/">
          <Image
            src="/assets/logo-dark.svg"
            alt="Logo"
            width={124}
            height={27}
            className="hidden cursor-pointer dark:block"
          />
          <Image
            src="/assets/logo-light.svg"
            alt="Logo"
            width={124}
            height={27}
            className="block cursor-pointer dark:hidden"
          />
        </Link>
        <div className="flex items-center gap-[30px] xl:gap-[80px]">
          {/* Links */}
          <div>
            <ul className="relative hidden h-[68px] items-center overflow-visible lg:flex">
              {links.map(({ href, label }) => (
                <li
                  key={href}
                  className="relative flex h-full items-center justify-center"
                >
                  <Link
                    href={href}
                    className="px-4 font-open-sans text-sm text-[#171663] dark:text-white"
                    onClick={() => {
                      setCurrentPath(href);
                      handleLinkClick();
                    }}
                  >
                    {label}
                  </Link>
                  {isLinkActive(currentPath, href) && (
                    <>
                      <div className="absolute bottom-0 left-0 right-0 h-[5px] overflow-visible rounded-t-[6px] bg-[#3E8AFF] shadow-lg shadow-blue-500" />
                      <div
                        className="absolute bottom-[1px] left-0 right-0 mx-auto h-[4px] w-[97%] overflow-visible rounded-t-[6px] bg-transparent shadow-lg shadow-blue-500"
                        style={{
                          boxShadow: "0 0 20px 0.5px rgba(50, 132, 255, 0.7)",
                        }}
                      />
                    </>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="flex items-center gap-2.5">
            <SignedIn>
              {/* Notification */}
              <div className="relative mr-2" ref={notificationDropdownRef}>
                <div
                  onClick={toggleNotificationDropdown}
                  className="cursor-pointer"
                >
                  {isLoading ? (
                    <Loader dimensions="md" />
                  ) : (
                    <>
                      <Image
                        src="/assets/icons/dark-notification.svg"
                        alt="Notification"
                        width={24}
                        height={24}
                        className="hidden dark:block"
                      />
                      <Image
                        src="/assets/icons/light-notification.svg"
                        alt="Notification"
                        width={24}
                        height={24}
                        className="dark:hidden"
                      />
                    </>
                  )}
                </div>
                {isNotificationOpen && (
                  <div className="absolute right-[-129px] top-12 z-30 w-fit rounded-lg border bg-white font-open-sans shadow-md dark:border-[#FFFFFF33] dark:bg-[#03071C] dark:text-white">
                    <Notification changelog={changelog} error={error} />
                  </div>
                )}
              </div>

              {/* Plan Button */}
              <Button
                as={Link}
                href="/app/plans"
                className={`hidden h-9 text-xs lg:flex ${getPlanButtonStyle()}`}
                endContent={<CircleFadingArrowUp size={14} />}
              >
                {getPlanLabel()}
              </Button>

              <Profile />
            </SignedIn>
          </div>

          {/* Menu */}
          <button onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? (
              <X className="block text-4xl text-[#171663] dark:text-white lg:hidden" />
            ) : (
              <Menu className="block text-4xl text-[#171663] dark:text-white lg:hidden" />
            )}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`fixed left-0 top-0 z-40 h-full w-64 transform bg-white dark:bg-[#03071C] ${
          isMobileMenuOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out`}
        ref={mobileMenuRef}
      >
        <div className="flex h-full flex-col p-4">
          <div className="mb-8 flex items-center justify-between">
            <Image
              src="/assets/logo-dark.svg"
              alt="Logo"
              width={124}
              height={27}
              className="hidden cursor-pointer dark:block"
            />
            <Image
              src="/assets/logo-light.svg"
              alt="Logo"
              width={124}
              height={27}
              className="block cursor-pointer dark:hidden"
            />
          </div>
          <ul className="relative flex w-fit flex-col space-y-3 overflow-visible">
            {links.map(({ href, label }) => (
              <li key={href} className="relative h-[35px]">
                <Link
                  href={href}
                  className="h-full font-open-sans text-sm text-[#171663] dark:text-white"
                  onClick={() => {
                    setCurrentPath(href);
                    handleLinkClick();
                  }}
                >
                  {label}
                </Link>
                {isLinkActive(currentPath, href) && (
                  <>
                    <div className="absolute bottom-0 left-0 right-0 h-[5px] overflow-visible rounded-t-[6px] bg-[#3E8AFF] shadow-lg shadow-blue-500" />
                    <div
                      className="absolute bottom-[1px] left-0 right-0 mx-auto h-[4px] w-[97%] overflow-visible rounded-t-[6px] bg-transparent shadow-lg shadow-blue-500"
                      style={{
                        boxShadow: "0 0 20px 0.5px rgba(50, 132, 255, 0.7)",
                      }}
                    />
                  </>
                )}
              </li>
            ))}
          </ul>

          {/* Mobile Plan Button */}
          <Button
            as={Link}
            href="/app/plans"
            className={`mt-10 ${getPlanButtonStyle()} h-[44px] font-open-sans text-[14px] tracking-[-0.02em]`}
            endContent={
              <Image
                src="/assets/arrowlineup.svg"
                alt="Upgrade arrow"
                width={12}
                height={12}
              />
            }
          >
            {getPlanLabel()}
          </Button>
        </div>
      </div>
    </div>
  );
};
