"use client";

import { useUser } from "@clerk/nextjs";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
import { Loader } from "../components/loader.component";
import { Navbar } from "../components/navbar";

type AppLayoutProps = {
  children: React.ReactNode;
};

const AppLayout = ({ children }: AppLayoutProps) => {
  const router = useRouter();
  const { user, isLoaded } = useUser();

  useEffect(() => {
    if (!user && isLoaded) router.push("/");
  }, [user, isLoaded]);

  if (!isLoaded) return <Loader dimensions="lg" />;

  return (
    <>
      <Navbar />
      {children}
    </>
  );
};

export default AppLayout;
