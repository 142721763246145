import { links } from "@/app/config";
import { CannyChangelog } from "@/app/types";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Chip,
  Link,
  ScrollShadow,
} from "@nextui-org/react";

type NotificationProps = {
  changelog?: CannyChangelog;
  error: Error | null;
};

const Notification = ({ changelog, error }: NotificationProps) => {
  if (error) {
    return (
      <Card className="w-[315px]">
        <CardBody>
          <p className="text-danger text-xs">Error: {error.message}</p>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card className="w-[315px]">
      <CardHeader className="justify-center border-b border-divider py-2">
        <h4 className="text-sm font-medium">Changelog</h4>
      </CardHeader>

      <ScrollShadow className="h-[350px]">
        <CardBody className="gap-0 p-0">
          {changelog?.entries.map((entry, index) => (
            <Link
              href={entry.url}
              key={index}
              isExternal
              className="block p-2 border-b border-divider hover:bg-default-100 transition-colors"
            >
              {entry.types.includes("new") && (
                <div className="line-clamp-1 mb-0.5">
                  <Chip
                    color="success"
                    size="sm"
                    variant="solid"
                    className="mr-1.5 scale-90"
                  >
                    NEW
                  </Chip>
                  <span className="text-sm font-medium inline align-middle">
                    {entry.title}
                  </span>
                </div>
              )}
              <p className="text-xs text-default-500 line-clamp-2">
                {entry.plaintextDetails}
              </p>
            </Link>
          ))}
        </CardBody>
      </ScrollShadow>

      <CardFooter className="justify-between px-3 py-2 border-t border-divider">
        <span className="text-xs text-default-400">Powered by Canny · RSS</span>
        <Link
          href={links.canny.changelog}
          size="sm"
          isExternal
          className="text-xs text-default-400 hover:text-default-500"
        >
          Full Changelog
        </Link>
      </CardFooter>
    </Card>
  );
};

export default Notification;
