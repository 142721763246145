import { SubscriptionResponse } from "./route";

export const fetchCurrentUserPlan = async () => {
  const response = await fetch("/api/stripe/current-plan");
  return response.json();
};

export const getSubscriptionStatusLabel = (
  subscription: SubscriptionResponse
) => {
  if (subscription.status === "active") return "Active";
  if (subscription.status === "inactive") return "Inactive";
  return "Unknown";
};
