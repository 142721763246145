import { links } from "@/app/config";
import { useClerk } from "@clerk/nextjs";
import {
  Avatar,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  User,
} from "@nextui-org/react";
import gravatar from "gravatar";
import { HelpCircle, LogOut, Settings } from "lucide-react";
import Link from "next/link";
import { useRouter } from "next/navigation";

export const Profile = () => {
  const router = useRouter();
  const { user, signOut } = useClerk();

  const handleLogout = () => {
    signOut();
    router.push("/");
  };

  const avatarUrl = gravatar.url(
    user?.emailAddresses[0].emailAddress || "",
    undefined,
    true
  );

  return (
    <Dropdown placement="bottom-end">
      <DropdownTrigger>
        <Avatar
          as="button"
          className="transition-transform"
          size="sm"
          src={avatarUrl}
        />
      </DropdownTrigger>
      <DropdownMenu
        aria-label="Profile menu"
        className="w-[260px]"
        itemClasses={{
          base: "gap-4",
        }}
      >
        <DropdownSection aria-label="Profile & Account" showDivider>
          <DropdownItem
            isReadOnly
            className="h-14 gap-2"
            textValue="Profile information"
            key="profile"
          >
            <User
              name={user?.fullName}
              description={user?.emailAddresses[0].emailAddress}
              avatarProps={{
                size: "sm",
                src: avatarUrl,
              }}
            />
          </DropdownItem>
        </DropdownSection>

        <DropdownSection aria-label="Settings" showDivider>
          <DropdownItem
            as={Link}
            href={links.dashboard.config}
            startContent={<Settings className="h-4 w-4" />}
            key="preferences"
          >
            <p className="text-sm">Preferences</p>
          </DropdownItem>
        </DropdownSection>

        <DropdownSection aria-label="Help & Logout">
          <DropdownItem
            as="a"
            href={links.docs}
            target="_blank"
            startContent={<HelpCircle className="h-4 w-4" />}
            key="docs"
          >
            Docs
          </DropdownItem>
          <DropdownItem
            onClick={handleLogout}
            startContent={<LogOut className="h-4 w-4" />}
            color="danger"
            key="logout"
          >
            Logout
          </DropdownItem>
        </DropdownSection>
      </DropdownMenu>
    </Dropdown>
  );
};
